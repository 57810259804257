@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html,
body,
#__next,
main {
  height: 100%;
  font-family: "IBM Plex Sans", sans-serif;
}

textarea:focus,
input:focus {
  outline: none;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #444;
  color: #444;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #444;
  color: #444;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #444;
  color: #444;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #444;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.markdown ol {
  list-style: decimal;
  padding-left: 1.5em;
}
.markdown ul {
  list-style: disc;
  padding-left: 1.5em;
}
.markdown li {
  margin-bottom: 0.5em;
}
.overview.markdown li {
  margin-bottom: 1em;
}
.markdown hr {
  margin: 10px 0;
  border-color: #333;
}
.markdown pre.poetry {
  white-space: normal;
  font-family: inherit;
}
.markdown pre.poetry code {
  font-family: inherit;
}
.markdown a {
  color: #33a;
}
.markdown h1 {
  font-size: 1.5em;
  margin: 0.25em 0;
}
.markdown h2 {
  font-size: 1.25em;
  margin: 0.25em 0;
}
.markdown h3 {
  font-size: 1.1em;
}
.markdown a:hover {
  text-decoration: underline;
}

.markdown p {
  margin: 6px 0;
}

.markdown table {
  color: #333;

  thead {
    background-color: #ddd;
  }
  tr {
    border-bottom: 1px solid #ccc;
  }
  th,
  td {
    padding: 4px 8px;
  }
}

.list li {
  margin-bottom: 0.5em;
}

.Toastify__toast-body {
  white-space: pre-line;
}
